<template>
  <div>
    <h1>{{ `${isEdit ? "Edit" : "New"} DIY Document` }}</h1>
    <v-breadcrumbs :items="crumbs"></v-breadcrumbs>
    <base-loader :isLoading="isLoading"></base-loader>
    <v-form @submit.prevent="save()">
      <v-card>
        <v-card-text>
          <v-row>
            <v-col sm="6">
              <v-text-field v-model="productModel.title" label="Title" required></v-text-field>
              <v-text-field v-model="productModel.category" label="Category" required></v-text-field>
              <v-text-field v-model="productModel.completionTime" label="Document Completion Time" required></v-text-field>
              <v-text-field v-model="productModel.price" label="Price" required></v-text-field>
              <v-combobox v-if="tags && tags.records.length > 0" v-model="productTagList" :items="tags.records" item-text="name" item-value="id" :search-input.sync="search" hide-selected label="Add some tags" multiple persistent-hint small-chips clearable>
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        No results matching "<strong>{{ search }}</strong
                        >". Press <kbd>enter</kbd> to create a new one
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </v-col>
            <v-col sm="3">
              <h3>Featured Image</h3>
              <v-img :aspect-ratio="16 / 9" @click="triggerFile" v-if="productModel.featurePicture" :src="productModel.featurePicture" class="pointer img_upload--preview"></v-img>
              <div v-else class="img_upload--preview">
                <v-img :aspect-ratio="16 / 9" @click="triggerFile" :src="require('@/assets/img/NLA-logo-placeholder.jpg')" class="pointer"></v-img>
              </div>
            </v-col>
            <v-file-input v-model="productModel.newFeaturePicture" accept="image/*" ref="product_img" class="d-none"></v-file-input>
            <v-col sm="3">
              <h3>Preview Document</h3>
              <v-img @click="triggerPreviewFile" :aspect-ratio="16 / 9" v-if="productModel.previewDocument" src="/pdf.png" class="pointer document_upload--preview" contain></v-img>
              <div v-else class="document_upload--preview">
                <v-img @click="triggerPreviewFile" :aspect-ratio="16 / 9" :src="require('@/assets/img/NLA-logo-placeholder.jpg')" class="pointer"></v-img>
              </div>
            </v-col>
            <v-file-input v-model="productModel.newPreviewDocument" accept="application/pdf" ref="product_preview" class="d-none"></v-file-input>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea v-model="productModel.content" filled label="Content" auto-grow required></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea v-model="productModel.requiredInfo" filled label="Required Information" auto-grow required></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea v-model="productModel.disclaimer" filled label="Disclaimer" auto-grow required></v-textarea>
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <v-textarea v-model="productModel.digitalItem" filled label="Survey Iframe" auto-grow></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn type="submit" color="success">Save</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { Product } from "@/models/Product";
import { gql } from "apollo-boost";
import { getFileAsDataURL } from "@/helpers/functions";
export default {
  name: "ProductEdit",
  data() {
    return {
      isEdit: false,
      isLoading: false,
      productModel: new Product(),
      me: "",
      productsList: [],
      prices: {},
      search: null,
      productTagList: [],

      crumbs: [
        {
          text: "DIY Documents",
          disabled: false,
          href: "/admin/products",
        },
        {
          text: "DIY Document Details",
          disabled: false,
          href: `/admin/product/${this.$route.params.id}`,
        },
        {
          text: "DIY Document Edit",
          disabled: true,
        },
      ],
    };
  },
  created() {
    this.isEdit = this.$route.params.id != 0;
    this.isLoading = this.isEdit ? true : false;
  },
  apollo: {
    me: gql`
      query {
        me {
          id
        }
      }
    `,
    product: {
      query: gql`
        query Product($id: ID!) {
          product(id: $id) {
            id
            title
            content
            category
            price
            featurePicture
            previewDocument
            completionTime
            requiredInfo
            digitalItem
            disclaimer
            tagIDs
            tags {
              id
              name
              type
            }
          }
        }
      `,
      fetchPolicy: "network-only",
      skip() {
        return !this.isEdit;
      },
      variables() {
        const self = this;
        return {
          id: self.$route.params.id,
        };
      },
      result(response, key) {
        this.isLoading = false;
        this.productModel.title = this.product.title;
        this.productModel.content = this.product.content;
        this.productModel.id = this.product.id;
        this.productModel.price = this.product.price;
        this.productModel.category = this.product.category;
        this.productModel.featurePicture = this.product.featurePicture;
        this.productModel.previewDocument = this.product.previewDocument;
        this.productModel.completionTime = this.product.completionTime;
        this.productModel.requiredInfo = this.product.requiredInfo;
        this.productModel.tagIDs = this.product.tagIDs;
        this.productModel.digitalItem = this.product.digitalItem;
        this.productModel.disclaimer = this.product.disclaimer;
        this.product.tags.forEach((tag) => {
          this.productTagList.push(tag);
        });
      },
    },
    tags: {
      query: gql`
        query Tags($limit: Int!, $skip: Int!, $query: JSON!) {
          tags(limit: $limit, skip: $skip, query: $query) {
            records {
              id
              name
              type
            }
            count
          }
        }
      `,
      fetchPolicy: "network-only",
      variables() {
        const self = this;
        return {
          limit: 9999,
          skip: 0,
          query: {
            type: "product",
          },
        };
      },
    },
  },
  methods: {
    async save() {
      this.isLoading = true;
      const self = this;
      if (self.isEdit) {
        // update
        await this.updateProduct()
          .then(() => {
            self.productTagList.forEach(async (tag) => {
              if (typeof tag == "string") {
                const newTagId = await self
                  .createTag({
                    name: tag,
                    type: "product",
                    createdBy: self.me.id,
                  })
                  .then((tagObj) => {
                    if (tagObj == 0) {
                      this.$swal({
                        title: "Error",
                        text: "New tags were not saved, please try again later",
                        icon: "error",
                        confirmButtonColor: "#c79477",
                      });
                      return;
                    }
                    self.productTagList.push(tagObj);
                    self.productTagList = self.productTagList.filter((e) => e !== tag); // will return ['A', 'C']
                    this.updateProduct();
                  });
              }
            });
            this.$swal({
              title: "Success",
              text: "DIY Document was updated",
              icon: "success",
              confirmButtonColor: "#c79477",
            });
          })
          .catch((e) => {
            this.$swal({
              title: "Error",
              text: "Something went wrong, please check data and try again later!",
              icon: "error",
              confirmButtonColor: "#c79477",
            });
            console.log(e);
          });
      } else {
        // create
        await this.saveCreate()
          .then(() => {
            window.location.href = `/admin/product/${self.productModel.id}/edit`;
          })
          .catch((e) => {
            this.$swal({
              title: "Error",
              text: "Something went wrong, please check data and try again later!",
              icon: "error",
              confirmButtonColor: "#c79477",
            });
            console.log(e);
          });
      }
      this.isLoading = false;
    },

    async saveCreate() {
      const self = this;

      await this.createProduct().then((productId) => {
        self.productModel.id = productId;
        // need to add the new tags if any
        self.productTagList.forEach(async (tag) => {
          if (typeof tag == "string") {
            const newTagId = await self
              .createTag({
                name: tag,
                type: "product",
                createdBy: self.me.id,
              })
              .then((tagObj) => {
                if (tagObj == 0) {
                  this.$swal({
                    title: "Error",
                    text: "New tags were not saved, please try again later",
                    icon: "error",
                    confirmButtonColor: "#c79477",
                  });
                  return;
                }
                self.productTagList.push(tagObj);
                self.productTagList = self.productTagList.filter((e) => e !== tag);
                this.updateProduct();
              });
          }
        });
      });
    },

    async createTag(tag) {
      const self = this;

      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createTag($tag: TagCreateInput!) {
              createTag(tag: $tag) {
                id
                name
                type
              }
            }
          `,
          variables: {
            tag: tag,
          },
        });
        console.log(response);

        if (response?.data?.createTag?.id) {
          console.log("Created New Tag", response.data.createTag.name);
          return response.data.createTag;
        } else {
          return 0;
        }
      } catch (e) {
        this.$swal({
          title: "Error",
          text: e.message,
          icon: "error",
          confirmButtonColor: "#c79477",
        });
      }
    },

    async createProduct() {
      const self = this;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation createProduct($product: ProductCreateInput!) {
              createProduct(product: $product) {
                id
              }
            }
          `,
          variables: {
            product: {
              title: self.productModel.title,
              content: self.productModel.content,
              category: self.productModel.category,
              createdBy: self.me.id,
              price: self.productModel.priceFloat,
              completionTime: this.productModel.completionTime,
              requiredInfo: this.productModel.requiredInfo,
              tagIDs: self.productModel.tagIDs,
              digitalItem: self.productModel.digitalItem,
              disclaimer: self.productModel.disclaimer,
            },
          },
        });

        if (response) {
          if (response?.data?.createProduct?.id) {
            if (self.productModel.featurePicture) await self.addFeaturePicture(response?.data?.createProduct?.id);
            if (self.productModel.previewDocument) await self.addPreviewDocument(response?.data?.createProduct?.id);
            return response.data.createProduct.id;
          }
        }
      } catch (e) {
        this.$swal({
          title: "Error",
          text: e.message,
          icon: "error",
          confirmButtonColor: "#c79477",
        });
      }
    },
    async addFeaturePicture(id) {
      const self = this;
      const check = self.productModel.newFeaturePicture instanceof File;
      if (!check) return;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateProduct($product: ProductUpdateInput!) {
              updateProduct(product: $product) {
                id
                updated
              }
            }
          `,
          variables: {
            product: {
              id: id,
              featurePicture: self.productModel.featurePicture,
            },
          },
        });
      } catch (e) {
        console.log(`Update Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
        console.log(self.errorMsg);
      }
    },
    async addPreviewDocument(id) {
      const self = this;
      const check = self.productModel.newPreviewDocument instanceof File;
      if (!check) return;
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateProduct($product: ProductUpdateInput!) {
              updateProduct(product: $product) {
                id
                updated
              }
            }
          `,
          variables: {
            product: {
              id: id,
              previewDocument: self.productModel.previewDocument,
            },
          },
        });
      } catch (e) {
        console.log(`Update Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
        console.log(self.errorMsg);
      }
    },
    async updateProduct() {
      const self = this;
      self.productModel.tagIDs = [];
      self.productTagList.forEach(async (tag) => {
        if (typeof tag == "object") {
          self.productModel.tagIDs.push(tag.id);
        }
      });
      try {
        const response = await self.$apollo.mutate({
          mutation: gql`
            mutation updateProduct($product: ProductUpdateInput!) {
              updateProduct(product: $product) {
                id
                updated
                product {
                  tagIDs
                  featurePicture
                  previewDocument
                }
              }
            }
          `,
          variables: {
            product: {
              id: self.productModel.id,
              title: self.productModel.title,
              content: self.productModel.content,
              category: self.productModel.category,
              price: self.productModel.priceFloat,
              completionTime: this.productModel.completionTime,
              requiredInfo: this.productModel.requiredInfo,
              tagIDs: self.productModel.tagIDs,
              digitalItem: self.productModel.digitalItem,
              disclaimer: self.productModel.disclaimer,
            },
          },
        });

        if (response) {
          this.addFeaturePicture(self.productModel.id);
          this.addPreviewDocument(self.productModel.id);
          console.log(response);
        }
      } catch (e) {
        console.log(`Update Failed ${JSON.stringify(e)}`);
        self.errorMsg = `${e.message}`;
        console.log(self.errorMsg);
      }
    },
    triggerFile() {
      this.$refs.product_img.$refs.input.click();
    },
    triggerPreviewFile() {
      this.$refs.product_preview.$refs.input.click();
    },
  },
  watch: {
    "productModel.newFeaturePicture": async function(newValue, oldValue) {
      this.productModel.featurePicture = await getFileAsDataURL(newValue);
    },
    "productModel.newPreviewDocument": async function(newValue, oldValue) {
      this.productModel.previewDocument = await getFileAsDataURL(newValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.img_upload {
  &--preview {
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
.document_upload {
  &--preview {
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }
}
</style>
